<template>
  <div class="app-container">
    <div class="flex-form">
      <el-form
        v-loading="loading"
        class="info-form"
        label-width="170px"
      >
        <el-form-item>
          <h2>
            View Admin User
          </h2>
        </el-form-item>

        <el-form-item label="Status">
          <div class="info-field info-field--no-line">
            <el-tag :type="user.status | adminUserStatusFilter">
              {{ user.status }}
            </el-tag>
          </div>
        </el-form-item>

        <el-form-item label="Uid">
          <div class="info-field">
            {{ user.userUid }}
          </div>
        </el-form-item>

        <el-form-item label="Username">
          <div class="info-field text-blue">
            {{ user.username }}
          </div>
        </el-form-item>

        <el-form-item label="Role">
          <div class="info-field">
            <span :class="user.role">{{ user.role }}</span>
          </div>
        </el-form-item>

        <el-form-item label="Created At">
          <div class="info-field">
            {{ user.createdAt | formatDate }}
          </div>
        </el-form-item>

        <el-form-item label="Updated At">
          <div class="info-field">
            {{ user.updatedAt | formatDate }}
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import permission from '@/directive/permission';
  import { getAdminUser } from '@/api/adminUser';

  export default {
    name: 'AdminUserView',
    directives: {
      permission,
    },
    data() {
      return {
        loading: false,
        userId: null,
        user: {},
      };
    },
    created() {
      this.userId = this.$route.params && this.$route.params.id;
      this.getAdminUser(this.userId);
    },
    activated() {
      this.getAdminUser(this.userId);
    },
    methods: {
      async getAdminUser(id) {
        try {
          this.loading = true;
          this.user = {};

          const data = await getAdminUser({
            userUid: id,
          });

          this.user = data.user;
          this.loading = false;
        } catch (e) {
          this.loading = false;

          this.$router.push({
            name: 'AdminUserList',
          });
        }
      },
    },
  };
</script>
