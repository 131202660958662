import Vue from 'vue';

export function listAdminUsers(params) {
  return Vue.$http({
    url: '/adminUsers/listAdminUsers',
    method: 'get',
    params,
  });
}

export function getAdminUser(params) {
  return Vue.$http({
    url: '/adminUsers/getAdminUser',
    method: 'get',
    params,
  });
}

export function updateAdminUser(data) {
  return Vue.$http({
    url: '/adminUsers/updateAdminUser',
    method: 'post',
    data,
  });
}

export function createAdminUser(data) {
  return Vue.$http({
    url: '/adminUsers/createAdminUser',
    method: 'post',
    data,
  });
}

export function deleteAdminUser(data) {
  return Vue.$http({
    url: '/adminUsers/deleteAdminUser',
    method: 'post',
    data,
  });
}

export function getRoles() {
  return [
    'admin',
  ];
}

export function getStatuses() {
  return [
    'active',
    'disabled',
  ];
}
